<template>
	<div>
		<ContentHeader title="Appointment Pasien" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">Tambah Appointment Pasien</h3>
					</div>
					<div class="card-body">
						<el-calendar>
						<template #dateCell="{ data }">
						<div style="width: 100%;height: 100%;" @click="tambahJanji(data.day)">
							<p :class="data.isSelected ? 'is-selected' : ''">
							{{ data.day.split('-').slice(2)[0] }}
						</p>
						</div>
						</template>
					</el-calendar>

					<el-dialog
						v-model="dialogVisible"
						title="Tambah Appointment Pasien"
						width="50%"
						:before-close="handleClose"
					>
						<div class="d-flex justify-content-between align-items-center">
							<p>Tanggal : {{ tanggal }}</p>
							<el-button @click="addAppointmentVisible = true" v-if="!addAppointmentVisible" size="small">Tambah Kunjungan</el-button>
							<el-button @click="addAppointmentVisible = false" v-if="addAppointmentVisible" size="small">Data Kunjungan</el-button>
						</div>
						<div id="dataAppointment" v-if="!addAppointmentVisible">
							<el-table :data="kunjungan" v-loading="loading_kunjungan" style="width: 100%">
								<el-table-column prop="nomor" label="Nomor Antrian" width="180" />
								<el-table-column prop="nama_pasien" label="Nama" width="180" />
								<el-table-column prop="nomor_telepon" label="Nomor Telepon" />
								<el-table-column prop="status" label="Status" />
								<el-table-column label="Action">
									<template #default="scope">
										<el-popconfirm title="Hapus Data?" @confirm="hapusData(scope.row.id)">
											<template #reference>
												<el-button type="danger"  :loading="loading">Batalkan</el-button>
											</template>
										</el-popconfirm>
									</template>
								</el-table-column>
							</el-table>
						</div>
						
						<div id="tambahAppointment" v-if="addAppointmentVisible">
							<el-select style="width:100%;" v-model="pasien_selected" v-loading="loading_pasien" filterable placeholder="Pilih Pasien">
									<el-option v-for="(item, index) in pasien" :key="index" :label="item.nama" :value="item.id">
									</el-option>
								</el-select>
								<el-select style="width:100%;" class="mt-2" v-model="dokter_selected" v-loading="loading_dokter" filterable placeholder="Pilih Dokter">
									<el-option
									v-for="(item, index) in dokter"
									:key="index"
									:label="item.nama_lengkap"
									:value="item.id"
									>
									</el-option>
								</el-select>
								<el-select style="width:100%;" class="mt-2" v-model="poli_selected" v-loading="loading_poli" filterable placeholder="Pilih Poli">
									<el-option
									v-for="(item, index) in poli"
									:key="index"
									:label="item.poli.nama"
									:value="item.poli.id"
									>
									</el-option>
								</el-select>
								<el-select style="width:100%;" class="mt-2" v-model="layanan_selected" v-loading="loading_layanan" filterable placeholder="Pilih layanan">
									<el-option
									v-for="(item, index) in layanan"
									:key="index"
									:label="item.nama"
									:value="item.id"
									>
									</el-option>
								</el-select>
						</div>
						<template #footer>
						<span class="dialog-footer">
							<el-button @click="dialogVisible = false">Cancel</el-button>
							<el-button type="primary" v-if="addAppointmentVisible" @click="prosesKunjungan" :loading="loading"
							>Confirm</el-button
							>
						</span>
						</template>
					</el-dialog>
					</div>
				</div>
			</div>
		</div>	
	</div>
</template>

<script setup>
	import { ref, onMounted, computed, reactive } from 'vue'
	import axios from 'axios'
	import store from '@/store'
	import ContentHeader from '@/components/ContentHeader'
	import { ElMessageBox, ElMessage } from 'element-plus'

	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const tanggal = ref(null)

	const dialogVisible = ref(false)
	const addAppointmentVisible = ref(false)

	const tambahJanji = (data) => {
		tanggal.value = data
		kunjungan.value = null
		pasien_selected.value = null
		dokter_selected.value = null
		poli_selected.value = null
		layanan_selected.value = null
		getKunjungan(tanggal.value)
		dialogVisible.value = !dialogVisible.value
		addAppointmentVisible.value = false
	}

const handleClose = (done) => {
	ElMessageBox.confirm('Are you sure to close this dialog?')
	.then(() => {
		done()
	})
	.catch(() => {
		// catch error
	})
}

	// PASIEN
	const pasien = ref([])
	const pasien_selected = ref(null)
	const loading_pasien = ref(false)

	const getPasien = async () => {
		loading_pasien.value = true
		let response = await axios.get('api/pasien/get/' + user.value.cabang_id)
		
		if (response.data == 'kosong') {
			loading_pasien.value = false
			pasien.value = []
		} else {
			loading_pasien.value = false
			pasien.value = response.data
		}
	}

	// DOKTER
	const dokter = ref([])
	const dokter_selected = ref('')
	const loading_dokter = ref(false)

	const getDokter = async () => {
		loading_dokter.value = true
		let response = await axios.get(`api/karyawan/getAllWhereJabatan/${user.value.cabang_id}/dokter`)

		if (response.data == 'kosong') {
			loading_dokter.value = false
			dokter.value = []
		} else {
			loading_dokter.value = false
				dokter.value = response.data
		}
	}
	// END DOKTER
	
	// POLI
	const poli = ref([])
	const poli_selected = ref('')
	const loading_poli = ref(false)

	const getPoli = async () => {
		loading_poli.value = true
		let response = await axios.get(`api/poli/getWhereAktifRelationPoli/${user.value.cabang_id}`)

		if (response.data == 'kosong') {
			loading_poli.value = false
			poli.value = []
		} else {
			loading_poli.value = false
			poli.value = response.data
		}
	}
	// END POLI
	
	// LAYANAN
	const layanan = ref([])
	const layanan_selected = ref('')
	const loading_layanan = ref(false)

	const getLayanan = async () => {
		loading_layanan.value = true
		let response = await axios.get(`api/layanan/getWhereAktif`)

		if (response.data == 'kosong') {
			loading_layanan.value = false
			layanan.value = []
		} else {
			loading_layanan.value = false
			layanan.value = response.data
		}
	}
	// END LAYANAN

	// Get Kunjungan
	const kunjungan = ref(null)
	const loading_kunjungan = ref(false)
	const getKunjungan= async (tanggal) => {
		loading_kunjungan.value = true
		let response = await axios.get(`api/kunjungan/getByDate/${user.value.cabang_id}/${tanggal}`)

		if (response.data == 'kosong') {
			kunjungan.value = null
			loading_kunjungan.value = false
		} else {
			kunjungan.value = response.data
			loading_kunjungan.value = false
		}	
	}
	// End Get Kunjungan

	// Proses Kunjungan
	const loading = ref(false)
	const kunjunganBaru = reactive({
		'cabang_id' 	: user.value.cabang_id,
		'pasien_id' 	: '',
		'dokter_id' 	: '',
		'poli_id' 		: '',
		'layanan_id' 	: '',
		'status' 		:'dalam antrian',
		'tanggal' 		: ''
	})

	const prosesKunjungan = () => {
		if (loading.value == false) {
			loading.value = true
			kunjunganBaru.pasien_id  = pasien_selected.value
			kunjunganBaru.dokter_id  = dokter_selected.value
			kunjunganBaru.poli_id    = poli_selected.value
			kunjunganBaru.layanan_id = layanan_selected.value
			kunjunganBaru.tanggal    = tanggal.value
			axios.post('api/kunjungan/appointment', kunjunganBaru)
			.then((response) => {
				if (response.data == 'sudah ada') {
					ElMessage({
					showClose: true,
					message: 'Pasien Dalam Antrian.',
					type: 'error',
				})	
						loading.value = false
				} else {
					ElMessage({
					showClose: true,
					message: 'Berhasil Menambahkan Data.',
					type: 'success',
				})
						loading.value = false
						dialogVisible.value = !dialogVisible.value
						addAppointmentVisible.value = false
				}
			})
			.catch((error) => {
				ElMessage({
					showClose: true,
					message: 'Gagal Menambahkan Data.',
					type: 'error',
				})
				loading.value = false
				console.log(error) 
			})
		}
	}

	const hapusData = async (id) => {
		loading.value = true
		axios.delete(`api/kunjungan/destroy/${id}/${cabang.value.tipe}`)
		.then(() => {
			ElMessage({
				showClose: true,
				message: 'Berhasil Menghapus Data.',
				type: 'success',
			})
			loading.value = false
			getKunjungan()
		})
		.catch((error) => {
			ElMessage({
				showClose: true,
				message: 'Gagal Menghapus Data.',
				type: 'error',
			})
			loading.value = false
			console.log(error) 
		})
	}
	// End Proses Kunjungan

	onMounted(() => {
		getPasien()
		getDokter()
		getPoli()
		getLayanan()
	})
</script>

<style>
.is-selected {
color: #1989fa;
}

:root {
--el-color-primary: #409eff;
--el-color-success: #67c23a;
--el-color-warning: #e6a23c;
--el-color-danger: #f56c6c;
--el-color-error: #f56c6c;
--el-color-info: #909399;
}
</style>